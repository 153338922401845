import React from "react"
import { Link } from "gatsby"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import Background from "../../../assets/images/servicesHeader/web_icon.svg"
import arrow from "../../../assets/images/servicesPages/arrowDown.png"
import nuyu from "../../../assets/images/servicesPages/nuyu.png"
import badge from "../../../assets/images/servicesPages/web-badge.png"
import acquia from "../../../assets/images/servicesPages/acquia.png"
import drupal from "../../../assets/images/servicesPages/drupal_logo.png"
import magnolia from "../../../assets/images/servicesPages/magnolia.png"
import magento from "../../../assets/images/servicesPages/magento.png"
import shopify from "../../../assets/images/servicesPages/shopify.png"
import word_press from "../../../assets/images/servicesPages/word-press.png"


export default () => (
    <div>
        <SEO title={'ICON Worldwide | Web Development Services'} 
            description="From startups to big enterprises: we help them all create user-friendly, responsive websites on open source CMS’s. Discover our web development services!"
        canonical={'https://icon-worldwide.com/services/website-development'} />
        <Header headerTitle="Website Development" headerSubTitle="on the cutting edge of technology and user experience"  headerBackground={Background}/>
        <div id="servicesPagesInner">
            <div className="winningTeam">
                <div className="text-info">
                    <p>For startup ventures to world leading enterprises we create user friendly,
                        responsive websites on open source CMS’s. We help start up ventures get online
                        fast, with smart, professional and sustainable solutions. We build easy to use
                        CMS’s which allow <a href="https://www.designrush.com/agency/ecommerce/small-business" target="_blank">small businesses</a> to manage their websites and operate cost
                        efficiently. Every site is scaleable, easy to expand as businesses grow and evolve.</p>
                            
                    <p>For large organizations, we develop smart, template based solutions which are
                        both scaleable and a sustainable long-term investment. Whether tasked with
                        integrating with existing platforms or building a new system we will work with
                        you to create a system architecture that meets the requirements of your business.</p>
                           
                    <p>We have built websites for large universities, global banks and pharmaceutical
                        companies and lots of small business start-ups. For many of these companies we stay
                        on as web masters, running the daily operations of their digital businesses from our Zürich offices.
                    </p>
                </div>
            </div>
            <img className="arrowDown web-arrow" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <div className="normal-image">
                <img src={nuyu} alt="Website development, ICON Worldwide, nuyu" title="ICON Worldwide, Website development, nuyu "/>
                <img src={badge} className="web-badge" alt="Website development, ICON Worldwide, best web designs" title=" ICON Worldwide, Website development, best web designs "/>
            </div>

            <div className="twoColumns">
                <div className="block-two">
                    <h2>Front-end Technologies</h2>
                    <p>We put the emphasis on the user experience, fast, dynamic and responsive web design using the latest technologies, including:</p>
                    <div className="words">
                        <h3>HTML5</h3><i>/</i><h3>CSS3</h3><i>/</i><h3>ReactJS</h3><i>/</i><h3>GatsbyJS</h3><i>/</i><h3>VueJS</h3><i>/</i><h3>NuxtJs</h3>
                        <i>/</i><h3>AngularJS</h3>
                    </div>
                </div>

                <div className="block-two">
                    <h2>Back-end Technologies</h2>
                    <p>We will work with you to assess the best technologies for your product based on factors such as operations, scaleability and flexibility for future development. 
                    Our full-stack team is expert in languages including:</p>
                    <div className="words">
                        <h3>C#</h3><i>/</i><h3>Java</h3> <i>/</i><h3>PHP</h3><i>/</i><h3>Symphony</h3><i>/</i><h3>Laravel</h3><i>/</i><h3>Node.js</h3><i>/</i><h3>Express.js</h3><i>/</i><h3>Django</h3>
                        <i>/</i><h3>Python</h3><i>/</i><h3>Flask</h3><i>/</i><h3>Ruby on Rails</h3>
                    </div>
                </div>
            </div>
            <div className="blackLine"></div>

            <div className="twoColumns">
                <div className="block-two web-line">
                    <h2>Custom and Open Source CMS Development</h2>
                    <p>Our developers are platform agnostic, expert front-end and php developers.
                        We assess the business case and present the best technology solution for development.
                        ICON Worldwide is recognized as a leading PHP Development Firm.</p>
                    <p>We work with all major CMS platforms, so able to adapt easily to clients working with established systems.
                        We are expert <Link to="/services/acquia-and-drupal-development">Drupal</Link>, Site Core and <Link to="/services/magnolia-development-company">Magnolia</Link> developers, experienced developing complex, custom, multi-site and commerce platforms.</p>
                </div>

                <div className="block-two web-page">
                    <div className="images-links cms-images-web">
                        <h2>Learn more about our expert Drupal and Magnolia CMS development:</h2>
                        <Link to="/services/acquia-and-drupal-development"><img className="joomla" src={drupal} alt="Website development, ICON Worldwide, drupal" title=" ICON Worldwide, Website development, drupal"/></Link>
                        <Link to="/services/magnolia-development-company"><img src={magnolia} alt="Website development, ICON Worldwide, magnolia certified partner" title="ICON Worldwide, Website development, magnolia certified partner"/></Link>
                        <Link to="/services/acquia-and-drupal-development"><img src={acquia} alt=" Website development, ICON Worldwide, acquia certified" title="ICON Worldwide, Website development, acquia certfied"/> </Link>
                    </div>
                    <div className="cms-images-web">
                        <img src={magento} alt="m Website development, ICON Worldwide, magento logo" title=" ICON Worldwide, Website development, magento logo"/>
                        <img src={shopify} alt="shopify"/><img src={word_press} alt="Website development, ICON Worldwide, WordPress logo" title=" ICON Worldwide, Website development, WordPress logo"/>
                    </div>
                </div>
            </div>
            <div className="yellowLine"></div>
            <ServicesBottomNav/>
        </div>
        <Footer noScrollbar="true"/>
    </div>
)